import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import ImageCVRTRow from "src/containers/ImageCVRTRow";


const TopRow = ({ page }) => {
  return (
    <StaticQuery
      query={graphql`
          query {
            repairsAndServices: file(relativePath: { eq: "images/commercials/repairsAndServices.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
      render={data => (
        <ImageCVRTRow image={data.repairsAndServices.childImageSharp.fluid}/>
      )}
    />
  );
};

TopRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default TopRow;