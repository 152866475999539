import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const MiddleRow = ({ page: { config: { middleRow }}}) => {
  return (
    <Column className={css.middleRow} direction="cc">
      <Header primaryText={middleRow.headingPrimary} secondaryText={middleRow.headingSecondary} tertiaryText={middleRow.headingTertiary}/>
      <p>{middleRow.textOne}</p>
      <p>{middleRow.textTwo}</p>
    </Column>
  );
};

MiddleRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default MiddleRow;