import React from "react";
import {StaticQuery, graphql, Link} from "gatsby";

import Column from "src/components/Column";
import {TopRow, MiddleRow, BottomRow} from "src/components/pages/RepairsAndServices";
import {withSEO} from "src/hoc/SEO";
import {pages} from "src/config";
import Img from "gatsby-image";
import css from "../../../containers/TestCentreNavigation/index.module.scss";


const RepairsAndServicesPage = props => {

    return (
        <StaticQuery
            query={graphql`
                query {
                  logo: file(relativePath: { eq: "images/test-centre/logo.png" }) {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              `}
            render={data => (
                <main>
                    <Column direction="cc">
                        <Link to='/' className={css.logoImg}>
                            <Img fluid={data.logo.childImageSharp.fluid}/>
                        </Link>
                        <TopRow page={pages.repairsAndServices}/>
                        <MiddleRow page={pages.repairsAndServices}/>
                        <BottomRow page={pages.repairsAndServices}/>
                    </Column>
                </main>
            )}
        />
    );
};

export default withSEO({
    title: pages.repairsAndServices.title
})(RepairsAndServicesPage);
