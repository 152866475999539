import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const BottomRow = ({ page: { config: { bottomRow }}}) => {

  const renderLevels = services => {
    return services.sort((a, b) => a.order - b.order)
    .map((service, i) => {
      return (
        <Column key={i} direction="cc" style={{flex: 1}}>
          <ul>
            {service.bullets.sort((a, b) => a.order - b.order).map((bullet, j) => <li key={j}>{bullet.text}</li>)}
          </ul>
        </Column>
      );
    });
  };

  return (
    <Column className={css.bottomRow} direction="cc">
      <Header primaryText={bottomRow.headingPrimary} secondaryText={bottomRow.headingSecondary}/>
      <Column className={css.services}>
        {renderLevels(bottomRow.services)}
      </Column>
    </Column>
  );
};

BottomRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default BottomRow;
